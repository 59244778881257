import React from "react";
import styled from "styled-components";
import { Button } from "../Core";
// import { Link } from "gatsby";


const ButtonMain = styled(Button)`
  background: ${({ theme}) => theme.goatColors.panelButtonBg  }; 
  border: none; //${({ theme}) => theme.goatColors.panelButtonBorder  };
  color: ${({ theme}) => theme.goatColors.panelButtonText  };
  border-radius: 0;
  box-shadow:  ${({ theme}) => theme.goatColors.panelButtonShadow  };
  text-align: center;
`;

const ButtonAlt = styled(Button)`
  background: ${({ theme}) => theme.goatColors.panelButtonBg  }; 
  border: none; //${({ theme}) => theme.goatColors.panelButtonBorder  };
  color: ${({ theme}) => theme.goatColors.panelButtonText  };
  border-radius: 0;
  box-shadow:  ${({ theme}) => theme.goatColors.panelButtonShadow  };
  text-align: center;
`;


const GoatButton = ({ style="main", text="", ...rest }) => {
  if ( style === "main" ) {
    return ( <ButtonMain {...rest} >{text}</ButtonMain>)
  }
  else {
    return( <ButtonAlt {...rest}>{text}</ButtonAlt> )
  }
};

export default GoatButton;
